<template>
  <div>
    <div class="centerx">
      <vs-popup class="holamundo" :title="`${userModal.action} user`" :active.sync="userModal.active">
        <div class="vx-row" data-vv-scope="item-modal">
          <div class="vx-col w-1/2 mt-5">
            <label class="vs-input--label">Name</label>
            <vs-input
              v-model="userModal.form.name"
              class="w-full"
              name="name"
              :danger="errors.has('name')"
              :danger-text="errors.first('name')"
              v-validate="'required|min:3|max:30'"
              size="normal"
            />
          </div>
          <div class="vx-col w-1/2 mt-5">
            <label class="vs-input--label">Username</label>
            <vs-input
              v-model="userModal.form.username"
              class="w-full"
              name="username"
              :danger="errors.has('username')"
              :danger-text="errors.first('username')"
              v-validate="'required|min:5|max:20'"
              size="normal"
            />
          </div>
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Clearance</label>
            <multiselect
              :class="{ 'is-danger': errors.has('clearances') }"
              v-validate="'required'"
              name="clearances"
              v-model="userModal.form.clearance"
              :options="fillables.clearances"
              :custom-label="({ name }) => name"
              label="name"
              track-by="name"
              :max-height="175"
              placeholder="Select clearance"
            />
            <div
              v-if="errors.has('clearances')"
              class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
              style="height: 19px;"
            >
              <span class="span-text-validation">
                {{ errors.first('clearances') }}
              </span>
            </div>
          </div>
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Password</label>
            <vs-input
              v-model="userModal.form.password"
              class="w-full"
              name="password"
              :danger="errors.has('password')"
              :danger-text="errors.first('password')"
              v-validate="passwordRule"
              size="normal"
              type="password"
            />
          </div>
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-5">
          <vs-button :disabled="this.errors.items.length !== 0" @click="upsertUser" :color="userModal.action === 'create' ? 'success' : 'warning'" type="filled" class="w-full">
            {{ userModal.action === 'create' ? 'Create New User' : 'Save changes' }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mx-auto self-center">
        <vx-card class="mb-base">
          <div class="vx-row" id="invoice-detail">
            <div class="vx-col flex-1" id="account-info-col-1">
              <table>
                <tr>
                  <td class="font-semibold">
                    Name
                  </td>
                  <td>{{ branch.name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Phone
                  </td>
                  <td>{{ branch.phone }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    City
                  </td>
                  <td>
                    {{ branch.city }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="vx-col flex-1" id="account-info-col-2">
              <table>
                <tr>
                  <td class="font-semibold">
                    Code
                  </td>
                  <td>
                    <vs-chip color="primary">
                      {{ branch.code }}
                    </vs-chip>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="font-semibold">
                    Total Transaction
                  </td>
                  <td>-</td>
                </tr> -->
              </table>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mx-auto self-center">
        <vx-card>
          <div class="vs-con-loading__container" id="div-with-loading">
            <vs-table stripe :data="users">
              <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Username</vs-th>
                <vs-th>Role</vs-th>
                <vs-th>Last Activity</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].name">
                    {{ data[indextr].name }}
                  </vs-td>

                  <vs-td :data="data[indextr].username">
                    {{ data[indextr].username }}
                  </vs-td>

                  <vs-td :data="data[indextr].clearance">
                    <clearance-chip :data="data[indextr].clearance" />
                  </vs-td>

                  <vs-td :data="data[indextr].last_activity">
                    {{ data[indextr].last_activity }}
                  </vs-td>

                  <vs-td>
                    <vx-input-group>
                      <vs-button size="small" color="warning" icon="edit" @click="() => loadUserEdit(indextr)" />
                    </vx-input-group>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div slot="footer">
            <vs-row vs-type="flex" vs-justify="space-between">
              <vs-button color="primary" type="filled" to="/branches">
                Back
              </vs-button>
              <vs-button color="success" type="filled" icon="add" size="small" @click="loadUserNew" />
            </vs-row>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import BranchRepo from '@/repositories/branch_repo';
import UserRepo from '@/repositories/user_repo';
import ClearanceChip from '@/components/ClearanceChip.vue';
import { CLEARANCE_NAME } from '@/utils/constants';
import { isEmpty } from '@/utils/helpers';

export default {
  data: () => ({
    branch: {
      id: null,
      name: null,
      phone: null,
      code: null,
      city: null
    },
    users: [],
    userModal: {
      action: 'create',
      active: false,
      form: {
        id: null,
        name: null,
        username: null,
        clearance: null,
        password: null
      }
    },
    fillables: {
      clearances: [{
        name: 'Super Admin',
        value: 10
      }]
    },
    clearanceName: CLEARANCE_NAME
  }),
  methods: {
    async loadData() {
      return BranchRepo.detail(this.$route.params.id)
        .then(({ data: content }) => {
          this.branch = {
            id: content.id,
            name: content.name,
            phone: content.phone,
            code: content.code,
            city: content.city
          };

          return content.id;
        })
        .then(id => UserRepo.list(20, id))
        .then(({ data: { data: users } }) => {
          this.users = users.map(user => ({
            ...user,
            last_activity: moment(user.updated_at).format('DD/MM/YYYY HH:mm:ss')
          }));
        });
    },
    loadUserEdit(index) {
      const user = this.users[index];
      this.userModal.form = {
        ...user,
        clearance: { name: this.clearanceName[user.clearance], value: user.clearance },
        password: null
      };
      this.userModal.action = 'update';
      this.userModal.active = true;
    },
    loadUserNew() {
      this.clearUserForm();
      this.userModal.action = 'create';
      this.userModal.active = true;
    },
    clearUserForm() {
      this.userModal.form = {
        id: null,
        name: null,
        username: null,
        clearance: null,
        password: null
      };
      this.$validator.reset();
    },
    async checkUserModal() {
      await this.$validator.validate('name');
      await this.$validator.validate('username');
      await this.$validator.validate('clearances');
      await this.$validator.validate('password');

      return isEmpty(this.errors.items);
    },
    async upsertUser() {
      const noError = await this.checkUserModal();
      if (noError) {
        const payload = {
          name: this.userModal.form.name,
          username: this.userModal.form.username,
          branch_id: this.branch.id,
          password: this.userModal.form.password,
          clearance: Number(this.userModal.form.clearance.value)
        };

        const actionIsCreate = this.userModal.action === 'create';
        const action = actionIsCreate ? UserRepo.create(payload) : UserRepo.update(this.userModal.form.id, payload);
        return action
          .then(() => {
            this.$vs.notify({
              title: 'Success',
              text: actionIsCreate ? 'New user created' : 'User updated',
              color: 'success',
              position: 'bottom-center'
            });
            this.userModal.active = false;
            this.clearUserForm();
            return this.loadData();
          })
          .catch((err) => {
            this.$vs.notify({
              title: 'Whoops',
              text: err.message,
              color: 'danger',
              position: 'bottom-center'
            });
          });
      }
    },
    isEmpty
  },
  components: {
    ClearanceChip
  },
  computed: {
    passwordRule() {
      return this.userModal.action === 'create' ? 'required|min:6|max:30' : 'min:6|max:30';
    }
  },
  beforeMount() {
    this.fillables.clearances = Object.keys(this.clearanceName).map(item => ({ name: this.clearanceName[item], value: item }));
  },
  async mounted() {
    await this.loadData();
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#invoice-detail {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

</style>
