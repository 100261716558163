<template>
  <vs-chip :color="color">
    {{ name }}
  </vs-chip>
</template>

<script>
import ClearanceMixin from '@/utils/mixins/clearance';

export default {
  mixins: [ClearanceMixin],
  props: ['data'],
  data: () => ({
    name: '-',
    color: 'primary'
  }),
  methods: {
    setProperty() {
      switch (this.data) {
        case this.clearance.SUPER_ADMIN:
          this.name = 'Super Admin';
          this.color = 'teal';
          break;

        case this.clearance.BRANCH_ADMIN:
          this.name = 'Branch Admin';
          this.color = 'teal';
          break;

        case this.clearance.FINANCE:
          this.name = 'Finance';
          this.color = 'green';
          break;

        case this.clearance.TECHNICIAN:
          this.name = 'Technician';
          this.color = 'grey';
          break;

        case this.clearance.BLOCKED:
          this.name = 'Blocked';
          this.color = 'danger';
          break;

        default:
          this.name = '-';
          this.color = 'grey';
          break;
      }
    }
  },
  watch: {
    data() {
      this.setProperty();
    }
  },
  beforeMount() {
    this.setProperty();
  }
};
</script>
